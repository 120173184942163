import * as database from "../utilities/database.js";
import * as io from "../utilities/io.js";

// The download queue is and array of {id:id,f:filename,s:service}
// and is stored in indexedDB table "cache" as a JSON string

function addToQueue(miniNodes) {
	return new Promise(async function (resolve, reject) {
		let lookup = {};
		let queue;
		console.log("addToQueue");
		console.log(miniNodes);
		let jsonQueue = await database.getFromDB("cache", "downloadQueue");
		if (jsonQueue) {
			queue = JSON.parse(jsonQueue);
			console.log("queue from db");
			console.log(queue);
			// build has table of existing queue ids
			for (let i = 0; i < queue.length; i++) lookup[queue[i].id] = true;
			for (let i = 0; i < miniNodes.length; i++) {
				let id = miniNodes[i].id;
				if (!lookup[id]) {
					queue.push(miniNodes[i]);
					lookup[id] = true;
				}
			}
			console.log("queue after add/merge");
			console.log(queue);
		} else {
			queue = miniNodes;
		}
		if (queue && queue.length > 0) {
			jsonQueue = JSON.stringify(queue);
			await database.putToDB("cache", "downloadQueue", jsonQueue);
		} else {
			queue = null;
		}
		console.log("download addToQueue");
		console.log(queue);
		resolve(queue);
	});
}

function processQueue(q) {
	return new Promise(async function (resolve, reject) {
		console.log("processQueue");
		if (q) console.log(q);
		let queue = q;
		let keys = [];
		if (!queue) {
			let jsonQueue = await database.getFromDB("cache", "downloadQueue");
			if (jsonQueue) {
				queue = JSON.parse(jsonQueue);
			} else {
				resolve();
				return;
			}
		}
		console.log(queue);
		let online = true; // assume we are online
		let remaining = [];
		for (let i = 0; i < queue.length; i++) {
			let file = queue[i].f;
			let inCache = await database.testInDB("itemCache", file);
			if (!inCache) {
				if (online) {
					let service = queue[i].s;
					let status = await new Promise(async function (resolve1, reject1) {
						console.log(file + " " + service);
						io.fetchFromWeb(file, service)
							.then((result) => {
								database
									.putToDB("itemCache", file, result)
									.then((key) => {
										// console.log("download key " + key);
										keys.push(key);
										resolve1(result.status);
									})
									.catch((e) => {
										remaining.push(queue[i]);
										resolve1(result.status);
									});
							})
							.catch((e) => {
								console.log(e);
								remaining.push(queue[i]);
								resolve1(e.status);
							});
					});
					if (status === 222) online = false;
				} else {
					remaining.push(queue[i]);
				}
			}
		}
		if (remaining.length > 0) {
			console.log("saving remaining");
			console.log(remaining);
			await database.putToDB("cache", "downloadQueue", JSON.stringify(remaining));
		} else {
			await database.deleteFromDB("cache", "downloadQueue");
		}
		console.log("processQueue return...");
		console.log(keys);
		resolve(keys.length > 0 ? keys : null);
	});
}
function downloadFiles(miniNodes) {
	console.log("downloadFiles...");
	return new Promise(async function (resolve, reject) {
		let queue = miniNodes ? await addToQueue(miniNodes) : null;
		await processQueue(queue);
		resolve();
	});
}
function getIds() {
	return new Promise(async function (resolve, reject) {
		let jsonQueue = await database.getFromDB("cache", "downloadQueue");
		if (jsonQueue) {
			let queue = JSON.parse(jsonQueue);
			let lookup = {};
			for (let i = 0; i < queue.length; i++) lookup[queue[i].id] = true;
			resolve(lookup);
		}
		resolve(null);
	});
}

export { addToQueue, processQueue, downloadFiles, getIds };
