/*
    Get some key material to use as input to the deriveKey method.
    The key material is a password supplied by the user.
    */
async function getKeyMaterial(encPw) {
	return window.crypto.subtle.importKey(
		"raw",
		encPw,
		{ name: "PBKDF2" },
		false,
		["deriveBits", "deriveKey"]
	);
}
/*
    Given some key material and some random salt
    derive an AES-KW key using PBKDF2.
    */
async function getKey(keyMaterial, salt) {
	return window.crypto.subtle.deriveKey(
		{
			name: "PBKDF2",
			salt: salt,
			iterations: 50000,
			hash: "SHA-256"
		},
		keyMaterial,
		{ name: "AES-KW", length: 256 },
		true,
		["wrapKey", "unwrapKey"]
	);
}

/*
    Wrap the given key and write it into the "wrapped-key" space.
    */
async function wrap(encPw, keyToWrap, salt) {
	// get the key encryption key
	const keyMaterial = await getKeyMaterial(encPw);
	const wrappingKey = await getKey(keyMaterial, salt);
	// console.log("wrap key ");
	// console.log(encPw);
	// console.log(salt);
	// console.log(wrappingKey);
	// console.log(await window.crypto.subtle.exportKey("raw",wrappingKey));
	const wrapped = await window.crypto.subtle.wrapKey(
		"raw",
		keyToWrap,
		wrappingKey,
		"AES-KW"
	);
	//const wrappedKeyBuffer = new Uint8Array(wrapped);
	// console.log("wrapped");
	// console.log(wrapped);
	return wrapped;
}
async function unwrap(encPw, toUnwrap, salt) {
	// console.log("unwrap ");
	// console.log(encPw);
	// console.log(salt);
	const keyMaterial = await getKeyMaterial(encPw);
	const wrappingKey = await getKey(keyMaterial, salt);
	// console.log(await window.crypto.subtle.exportKey("raw",wrappingKey));
	const wrappedKey = toUnwrap;
	// console.log("(un)wrapping key");
	// console.log(wrappingKey);
	// console.log("key to unwrap");
	// console.log(wrappedKey);
	const unwrapped = await window.crypto.subtle.unwrapKey(
		"raw",
		wrappedKey,
		wrappingKey,
		"AES-KW",
		"AES-CBC",
		false,
		["encrypt", "decrypt"]
	);
	// console.log("unwrapped");
	// console.log(unwrapped);
	return unwrapped;
}

export { wrap, unwrap };
