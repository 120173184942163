function setupTooltipAdjustments() {
	// from stackoverflow by Takit Isy
	var tooltips = document.querySelectorAll(".sfm-footnote");
	tooltips.forEach(function (tooltip, index) {
		// For each tooltip
		tooltip.addEventListener("mouseover", position_tooltip); // On hover, launch function below
	});
	//checkStorage();
}

// async function checkStorage() {
//     if (navigator.storage && navigator.storage.estimate) {
//         const quota = await navigator.storage.estimate();
//         // quota.usage -> Number of bytes used.
//         // quota.quota -> Maximum number of bytes available.
//         const percentageUsed = (quota.usage / quota.quota) * 100;
//         console.log(`You've used ${percentageUsed}% of the available storage.`);
//         const remaining = quota.quota - quota.usage;
//         console.log(`You can write up to ${Math.round(remaining/1024/1024/1024)} more Gbytes.`);
//     }
// }

function position_tooltip() {
	// mouseover (hover) handler
	// Get .tooltiptext sibling
	var tooltip = this.parentNode.querySelector(".tooltiptext");

	// Get calculated tooltip coordinates and size
	var tooltip_rect = this.getBoundingClientRect();

	var tipX = tooltip_rect.width + 5; // 5px on the right of the tooltip
	var tipY = -40; // 40px on the top of the tooltip
	// Position tooltip
	tooltip.style.top = tipY + "px";
	tooltip.style.left = tipX + "px";

	// Get calculated tooltip coordinates and size
	tooltip_rect = tooltip.getBoundingClientRect();
	// Corrections if out of window
	if (tooltip_rect.x + tooltip_rect.width > window.innerWidth)
		// Out on the right
		tipX = -tooltip_rect.width - 5; // Simulate a "right: tipX" position
	if (tooltip_rect.y < 0)
		// Out on the top
		tipY = tipY - tooltip_rect.y; // Align on the top

	// Apply corrected position
	tooltip.style.top = tipY + "px";
	tooltip.style.left = tipX + "px";
}

export default setupTooltipAdjustments;
