function toSimulatedURL(str) {
	// Replacements for more URL-friendly output for Matomo URL tracking (expecting more cleanup as library grows)
	// note: don't encodeURIComponent b/c Chinese characters become unreadable

	// Replace all spaces with hyphens (-)
	let newStr = str.replace(/ /g, "-");

	return newStr;
}
export { toSimulatedURL };
