import React, { useEffect, useState, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DownloadingIcon from "@mui/icons-material/Downloading";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import DownloadLaterIcon from "./DownloadLaterIcon.js";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { dateToString } from "../utilities/dateFormatter";
import { cyan } from "@mui/material/colors";
import CatalogList from "./CatalogList.js";
import * as database from "../utilities/database.js";
import * as bookkeeping from "../utilities/bookkeeping.js";
import * as download from "../utilities/download.js";
import axios from "axios";

<link rel="manifest" href="/manifest.json"></link>;
const dataPhase = {
	notStarted: 0,
	notInCache: 1,
	refreshing: 2,
	isReadyShouldCache: 3,
	isReadyExpired: 4,
	isReady: 5,
	complete: 6,
	error: -1
};

const WebCatalog = React.forwardRef((props, ref) => {
	const { tid, selected, handleBack, offline, ...others } = props;
	const [dataStatus, setDataStatus] = useState(dataPhase.notStarted);
	const catalog = useRef({ path: "/" }); // empty catalog
	// const initialTidProp = tid ? { tid: tid } : {};
	// const [tidProp, setTidProp] = useState(initialTidProp);
	const local = useRef(JSON.parse("{}"));
	const availHashes = useRef(JSON.parse("{}"));
	const downloading = useRef(null);
	const errorMessage = useRef("");
	const file = "/testFiles/WebCatalog.json";
	const webCatalogName = "WebCatalog";
	// if (initialTidProp !== tidProp) setTidProp(initialTidProp); // for subsequent calls to CatalogList
	console.log("WebCatalog... " + tid);
	console.log(downloading.current);

	let actionIcons = [];
	actionIcons[0] = [
		// offline
		<DownloadLaterIcon />,
		<PendingActionsOutlinedIcon />,
		<DownloadDoneOutlinedIcon />
	];
	actionIcons[1] = [
		// online
		<FileDownloadOutlinedIcon />,
		<DownloadingIcon />,
		<DownloadDoneOutlinedIcon />
	];

	function handleDownload(nodes) {
		console.log("handleDownload in WebCatalog " + nodes.length);
		let miniNodes = [];
		let merged = downloading.current || {};
		// console.log(merged);
		let adding = 0;
		nodes.forEach((n) => {
			let mini = { id: n.id, f: n.file, s: n.service };
			if (!merged[n.id]) {
				merged[n.id] = true;
				miniNodes.push(mini); // new nodes only
				adding += 1;
			}
		});
		// console.log("merged ");
		console.log(merged);
		if (adding > 0) downloading.current = merged; // hash table of id => downloading
		let p0 = new Promise(async function (resolve, reject) {
			if (adding > 0) {
				let localPromise = bookkeeping.addToLocal(catalog.current, nodes); // 1st arg = master
				let enqueuePromise = download.addToQueue(miniNodes);
				Promise.all([localPromise, enqueuePromise]).then((values) => {
					let newLocal = values[0];
					if (newLocal.contents) local.current = newLocal;
					else console.log(newLocal); // problem?
					let downloadQueue = values[1];
					console.log("enqueue done, length now " + downloadQueue.length);
					resolve(downloadQueue);
				});
			} else {
				resolve();
			}
		});
		let p1 = new Promise(async function (resolve, reject) {
			p0.then(async (queue) => {
				console.log("p0 done...");
				console.log(queue);
				if (!offline) await processDownloadQueue(queue);
				downloading.current = await download.getIds();
				console.log("resolving 2nd promise");
				resolve();
			});
		});
		return [p0, p1];
	}
	async function processDownloadQueue(queue) {
		console.log("wc processDownloadQueue");
		let newKeys = await download.processQueue(queue);
		if (newKeys) {
			for (let i = 0; i < newKeys.length; i++) {
				availHashes.current[newKeys[i]] = true;
			}
		}
	}
	function getChildrenStatus(parent) {
		// console.log("getChildrenStatus " + parent.name);
		let status = bookkeeping.testChildrenInCatalog(local.current, parent);
		let children = parent.contents;
		if (children && children.length > 0)
			for (let i = 0; i < children.length; i++) {
				if (downloading.current && downloading.current[children[i].id])
					status[i] = 1;
				else status[i] = 2 * status[i];
			}
		// console.log(status);
		// let test = [];
		// for (let i=0; i<parent.contents.length;i++) test.push(parent.contents[i].file);
		// bookkeeping.benchmark(test);
		return status; // available is only a Promise at this
	}
	function getAvailabilityByHash(nodeHashes) {
		// console.log("getAvailabilityByHash, offline "+offline+", "+nodeHashes.length+" hashes");
		let available = null;
		if (offline) {
			// only process if offline
			available = [];
			for (let i = 0; i < nodeHashes.length; i++) {
				let isAvailable = availHashes.current[nodeHashes[i]] ? true : false;
				// if (isAvailable) console.log("hash available "+i+" of "+nodeHashes.length);
				available.push(isAvailable);
			}
		}
		return available;
	}

	useEffect(() => {
		const initialize = async () => {
			// get the ids of files queued for download
			let p1 = download.getIds(); // for these, use pending icon
			// wait for completion of bookkeeping.getLocal in case it is the first touch
			// on the "hlm" database, creating it and its tables
			let p2 = bookkeeping.getLocal(); // if none, creates empty
			let p3 = database.getFromDB("cache", webCatalogName);
			let results = await Promise.all([p1, p2, p3]);
			downloading.current = results[0];
			local.current = results[1];
			let temp = results[2];
			let next = dataPhase.complete;
			if (temp && temp.length > 0) {
				catalog.current = JSON.parse(temp);
				let version = catalog.current.dateTime;
				if (version && !offline) {
					let now = new Date();
					let elapsed = (now - Date.parse(version)) / 1000 / 3600; // hours elapsed
					if (elapsed > 12) version = null;
				}
				if (!version) next = dataPhase.isReadyExpired; // lazy update
			} else {
				next = dataPhase.notInCache;
			}
			let hashes = await database.getKeys("itemCache");
			let lookup = {};
			for (let i = 0; i < hashes.length; i++) {
				lookup[hashes[i]] = true;
			}
			availHashes.current = lookup;
			console.log("next WC data status will be " + next);
			setDataStatus(next);
		};
		const fetchRemoteCatalog = async () => {
			// console.log("fetch remote catalog");
			try {
				const response = await axios.get(file);
				if (response.status === 222) {
					setError("offline, unable to fetch the web catalog");
				} else {
					let data = response.data;
					addIdsPaths(data, null);
					const now = new Date();
					data.dateTime = dateToString(now);
					catalog.current = data;
					setDataStatus(dataPhase.isReadyShouldCache);
				}
			} catch (error) {
				setError("Error fetching the web catalog");
			}
		};

		let nextId = 1;
		function addIdsPaths(node, path) {
			node.id = nextId++;
			// console.log(node.name);
			if (path === null)
				node.path = ""; // catalog name is not part of path name
			else node.path = path + "/" + node.name;
			if (node.contents)
				for (let i = 0; i < node.contents.length; i++) {
					addIdsPaths(node.contents[i], node.path);
				}
		}

		let setError = (error) => {
			console.log("WebCatalog error " + error);
			errorMessage.current =
				"<h3 style='margin:18px; color:DarkRed'>" + error + "</h3>";
			setDataStatus(dataStatus.error);
		};

		// console.log("WebCatalog status " + dataStatus);
		switch (dataStatus) {
			case dataPhase.notStarted:
				let start = Date.now();
				initialize(); // start async data fetch from encrypted indexedDB cache, handle completion there
				let delay = Date.now() - start;
				console.log("WebCatalog initialized in " + delay + " ms");
				break;
			case dataPhase.notInCache:
				fetchRemoteCatalog();
				break;
			case dataPhase.refreshing:
			case dataPhase.isReadyShouldCache:
			case dataPhase.isReadyExpired:
			case dataPhase.isReady:
				// web catalog is now loaded

				setDataStatus(dataPhase.complete); // start rendering
				if (dataStatus === dataPhase.isReadyShouldCache) {
					// now cache the response (after user sees display at correct verse)
					const cacheData = async () => {
						// console.log("caching web catalog");
						await database.putToDB(
							"cache",
							webCatalogName,
							JSON.stringify(catalog.current)
						);
					}; // TODO: add error handling
					cacheData();
					// setDataStatus(dataPhase.complete);
				} else if (dataStatus === dataPhase.isReadyExpired) {
					// console.log("fetch current WebCatalog...");
					fetchRemoteCatalog();
				}
				break;
			default:
		}
	}, [dataStatus, offline, props.tid]);

	useEffect(() => {
		// on transition from offline to online,
		// download any queued files
		let doDownloads = async () => {
			await processDownloadQueue();
			downloading.current = await download.getIds();
			setDataStatus(dataPhase.refreshing);
		};
		if (!offline && downloading.current && dataStatus === dataPhase.complete) {
			console.log("doDownloads...");
			doDownloads();
		}
	}, [dataStatus, offline]);

	const theme = createTheme({
		palette: {
			primary: {
				main: cyan[800]
			}
		},
		components: {
			MuiListItem: {
				styleOverrides: {
					root: {
						"&.Mui-disabled": {
							opacity: 0.2
						}
					}
				}
			}
		}
	});
	// if (dataStatus !== dataPhase.complete) console.log("status is " + dataStatus);
	let tidProp = props.tid ? { tid: props.tid } : {};
	return (
		<ThemeProvider theme={theme}>
			<Box {...others} ref={ref} sx={{ pt: 9 }}>
				{dataStatus >= dataPhase.isReadyShouldCache && (
					<CatalogList
						catalog={catalog.current}
						{...tidProp}
						onSelect={props.selected}
						onAction={handleDownload}
						actionIcons={actionIcons}
						getStatus={getChildrenStatus}
						offline={offline}
						getAvailability={getAvailabilityByHash}
						handleBack={handleBack}
					/>
				)}
				{dataStatus === dataPhase.notStarted ||
				dataStatus === dataPhase.refreshing ? (
					<div
						dangerouslySetInnerHTML={{
							__html: "<span class='loader'></span>"
						}}
					/>
				) : (
					<div
						dangerouslySetInnerHTML={{
							__html: errorMessage.current
						}}
					/>
				)}
			</Box>
		</ThemeProvider>
	);
});

export default WebCatalog;
