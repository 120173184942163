// ToDo: relocate chapter text to be inline with first verse?

// USX is properly formed XML with additional metadata embedded in the xml tags
// It uses 2 types of tokens: normal paired markers (open <x... and close </x...), and
// only 2 special unpaired tags with open attribute of closed="false" :
//      fr
//      ft
// As in sfm, these are contained with f \f tags.
//
//      Tag     SFM equivalent
//      ------- -----------------------------
//      book    \id JHN -- Description      3 char identifier of book
//      chapter \c
//      para    \h, \toc1,2,3, \mt, \s1
//              \p, \pi, \q1,2, \li1, \b, mi
//      verse   \v, \va
//              (closed by <verse eid=...) to tag each end of verse with another tag
//      note    \f
//      char    embedded parts of note: \fr, \ft; maybe?: \bd, \it, \bdit
//              \add
//      figure  \fig    (skipped for now)
//
// code detects improper ordering of markers as well as unknown markers

// (1) fetch all lines and convert to single string data
// (2) process data into paragraphs, converting non-p tags into spans
// (3) convert paragraphs into HTML and append to output div
// (4) tag items (at least verses) so as to enable jumping to start or end of verse
// (5) FUTURE: allow starting at any book chapter, and track what div holds verse
//     so that can prepend on reverse scroll, append on forward scroll
//     I.e. each Chapter and verse div gets an ID to allow element.ScrollIntoView
//     If not yet rendered, generate new html
// (6) FUTURE: cache converted html to allow quick hopping to
//     previously rendered paragraphs

// following maps all sfm tags to broad processing types
//     para, break, verse, chapter, note, x (means discard)
import setupTooltipAdjustments from "./Tooltips";

// map all marker types to a small number of processing types, handle specifics in css
const sfmTypes = {
	p: "p",
	b: "b",
	m: "p",
	mi: "p",
	li: "p",
	li1: "p",
	li2: "p",
	q: "p",
	q1: "p",
	q2: "p",
	c: "c",
	mt: "p",
	ms: "p",
	h: "x",
	id: "x",
	toc1: "x",
	toc2: "x",
	toc3: "x",
	s1: "p",
	r: "p",
	d: "p",
	v: "v",
	va: "v",
	bd: "bdit",
	it: "bdit",
	bdit: "bdit",
	f: "f",
	add: "a",
	fig: "fig",
	"/v": "v",
	"/p": "x",
	x: "x"
}; // close markers and unknown marker

var lastMarker = "top of file";
var chapter = 0;
var newChap = false;
var found = {};

function usxToHtml(buffer) {
	if (buffer.indexOf("\r\n") > 0) buffer = buffer.replace(/\r\n */g, "");
	else if (buffer.indexOf("\n") > 0) buffer.buffer.replaceAll("\n", " ");
	var index = buffer.indexOf("<"); // process starting at first tag (discard any text headers)
	var length = buffer.length;
	var result = "";
	var nPara = 0;
	console.log("starting usxToHtml...");

	if (buffer.length < 2000 && buffer.indexOf("<html") >= 0) {
		console.log("invalid file or invalid server response");
		return "Error, invalid file or server response";
	}

	while (index < length) {
		// looping over <p> allows later quick painting to html
		var partial = null;
		[index, partial] = usxParaToHtml(buffer, index);
		//console.log("partial "+partial);
		result += partial;
		nPara += 1;
	}
	result += "<p> </p>";
	console.log("found tags...");
	console.log(found);
	console.log("nPara " + nPara);
	//console.log("total length "+result.length);
	//console.log(result);
	setupTooltipAdjustments();
	return result;
}
function usxParaToHtml(source, start) {
	// convert to HTML and return as <div>...</div>
	var result = "";
	var results = "";
	var endpoint = source.length;
	var contentEnd = 0;
	var [startXml, startTag, index] = getNextXml(source, start); // returns entire xml tag <...>
	//console.log("startTag "+startTag);
	while (index < endpoint) {
		// find starting tag for this paragraph
		if (startTag === "c") {
			results = parseChapter(startXml); // return chapter start marker (only)
			return [index, results];
		} else if (startTag === "b") {
			// return break between paragraphs
			return [index, "<br/>"];
		} else if (
			startTag &&
			(sfmTypes[startTag] === "p" || sfmTypes[startTag] === "v")
		)
			break; // accept verse as start marker, and later auto-wrap it in a paragraph
		if (sfmTypes[startTag] !== "x")
			console.log("Input file error? Skipping at " + index + "\n" + startXml);
		// skip all other non-paragraph types (e.g. building a table of contents)
		[startXml, startTag, index] = getNextXml(source, index); // skip stuff we are not interested in
	}
	if (sfmTypes[startTag] === "v")
		// verse xml has data we need, get it now
		[results, index] = parseVerse(source, startXml, startTag, index); // get starting verse #
	var [nextXml, nextTag, nextIndex] = getNextXml(source, index);
	//console.log("processing "+startTag+", "+nextTag);
	// var counter = 0;    // prevents infinite loops processing one paragraph
	// endpoint = 4000;     //DEBUG!!!
	while (nextIndex < endpoint) {
		//console.log(nextIndex+" "+nextTag);
		contentEnd = nextIndex - (nextXml ? nextXml.length : 0);
		if (contentEnd > index) {
			// absorb paragraph material up to the next tag
			results += source.slice(index, contentEnd);
			//console.log(results);
		}
		// diagnostic: track what tags (styles) are used
		if (!found[nextTag]) {
			//console.log("tag "+nextTag);
			found[nextTag] = true;
		}
		// now process based upon the type of the next tag
		const sfmType = nextTag ? sfmTypes[nextTag] : "x";
		//console.log("nextTag "+nextTag+" type "+sfmType);
		switch (sfmType) {
			case "p": // a new paragraph starts next, so return current results to caller
			case "x": // next tag type is ignored but terminates this paragraph
			case "/p": // close marker of some type of paragraph
				if (nextTag === "/p") contentEnd += nextXml.length;
				var divType = getDivType(startTag);
				if (divType === "v") {
					// auto wrap verses not within a paragraph
					console.log(" auto wrap " + results);
					results = "<p class='sfm-p'>" + results + "</p>";
				} else {
					results =
						"<" +
						divType +
						" class='sfm-" +
						startTag +
						"'>" +
						results +
						"</" +
						divType +
						">";
				}
				//if (startTag !== "p") console.log("divType "+divType+", nextTag "+nextTag);
				//console.log("results "+results);
				//console.log("from "+start+" to "+contentEnd);
				//if (results.length > 0)
				return [contentEnd, results];
			//break;
			case "b":
				result = "<br/>";
				break;
			case "c":
				//console.log("processing next type c");
				result = parseChapter(nextXml);
				break;
			case "v":
			case "/v":
				[result, nextIndex] = parseVerse(source, nextXml, nextTag, nextIndex);
				//console.log(result);
				break;
			// case "bdit":
			//     [result,nextIndex] = parseStyledChars(source,nextXml,nextIndex);
			//     break;
			case "f":
				[result, nextIndex] = parseFootnote(source, nextIndex);
				break;
			case "a":
				[result, nextIndex] = parseAddition(source, nextIndex);
				break;
			case "fig":
				result = ""; // ignore for now
				break;
			case null:
				console.log("null sfm type");
				nextIndex = endpoint;
				break;
			default:
				result = "unknown tag style " + nextTag + " at " + nextIndex;
				console.log(result);
				nextIndex = endpoint; // abort
			// correct the index and keep going?  write a routine to absorb unknown?
		}
		results += result; // still inside current paragraph
		index = nextIndex;
		[nextXml, nextTag, nextIndex] = getNextXml(source, index);
		//counter += 1;
	}
	//console.log("at end of data "+index+" "+nextIndex+" "+counter);
	contentEnd = nextIndex - (nextXml ? nextXml.length : 0);
	if (nextIndex > index) {
		results += source.slice(index, contentEnd); // capture text up to next marker
		//console.log(results);
	}
	divType = getDivType(startTag);
	return [source.length, "<" + divType + ">" + results + "</" + divType + ">"];
}
function getNextXml(source, index) {
	const [xml, next] = getElement(source, index);
	var tag = xml ? getAttribute(xml, "style") : null;
	if (tag === null) {
		if (xml.startsWith("</p")) tag = "/p";
		else if (xml.startsWith("<verse")) tag = "/v";
		else tag = "x";
	}
	return [xml, tag, xml ? next : source.length];
}
function getDivType(tag) {
	var type = sfmTypes[tag];
	if (type === "p") return "p";
	else if (type === "b") return "br";
	else if (type === "v") return "v";
	else return "span";
}
function parseChapter(xml) {
	chapter = getAttribute(xml, "number");
	//console.log("chapter "+chapter);
	if (!chapter) console.log(xml);
	newChap = true;
	return "<p id='c-" + chapter + "' ></p>";
}
function parseVerse(buffer, xml, tag, index) {
	var prefix = newChap ? "<span class='sfm-c'>" + chapter + "</span>" : "";
	newChap = false;
	if (tag === "v") {
		// structure: self closed start of verse; text; self closed end of verse
		var verse = getAttribute(xml, "number");
		const verse2 = getAttribute(xml, "altnumber");
		const temp = getAttribute(xml, "sid");
		if (temp) lastMarker = temp;
		if (verse2) verse += " " + verse2;
	} else if (tag === "va") {
		//console.log("va: "+lastMarker);
		var [endChar, , next] = getNextXml(buffer, index);
		if ("</char>" === endChar) {
			verse = buffer.slice(index, next - endChar.length);
			index = next;
		} else {
			verse = "???";
			console.log("unable to get verse for tag va at " + xml);
		}
	} else if (tag === "/v") {
		//const eid = getAttribute(xml,"eid");
		return ["", index]; // close verse span, add end of verse marker
	}
	var id = chapter + ":" + verse;
	return [
		prefix + "<span class='sfm-verse' id='" + id + "'>" + verse + "</span>",
		index
	];
}
//following could be made recursive to handle nesting of bold and italic
// function parseStyledChars(buffer,xml,index) {
//     console.log(xml);
//     const type = getElementType(xml);
//     if (type === "chapter") { // inline chapter number into first paragraph
//         // this will require caching number to use ahead of verse 1
//         const n = getAttribute(xml,"number")
//         return ["<span class='sfm-c'>"+n+"</span>",index];
//     }
//     console.log("unrecognized character style at "+lastMarker+" "+xml);
//     return ["*****",index]; // go find next open (close) tag
// }
function parseFootnote(buffer, start) {
	// contains 2 <char...></char...> runs with styles \fr footnote reference, \ft footnote text
	// footnote is converted to popup with fr as reference header, ft as text
	// note: ft continues through all text until </note>, possibly beyond first </char> and
	// additional <char></char> pairs
	var [element, style, index] = getNextXml(buffer, start); // first contained <char...
	// var fr = null;
	var ft = null;
	while (element) {
		// element is the not yet processed piece
		if (style === "fr") {
			// found fr
			var [endChar, , next] = getNextXml(buffer, index);
			if (endChar !== "</char>") {
				const msg = "*** no /char after " + element + "***";
				console.log(msg);
				return [msg, next - endChar.length];
			}
			// fr = buffer.slice(index,next-endChar.length);
		} else if (style === "ft") {
			// found start of ft
			[endChar, , next] = getNextXml(buffer, index);
			if (endChar !== "</char>") {
				const msg = "*** no /char after " + element + "***";
				console.log(msg);
				return [msg, next - endChar.length];
			}
			ft = buffer.slice(index, next - endChar.length);
		} else if (style === "add") {
			// addition style within ft
			var [result, index2] = parseAddition(buffer, index); // absorbs </char>
			next = index2;
			ft = ft ? ft + result : result;
		} else if (element === "</note>") {
			return [
				"<span class='sfm-footnote'>*<span class='tooltiptext'>" +
					ft +
					"</span></span>",
				index
			];
		} else {
			console.log(
				"invalid structure before </note/ at " + lastMarker + ": " + element
			);
		}
		[element, style, index] = getNextXml(buffer, next); // bump to next tag
	}
	const msg = "<div>bad footnote at" + index + " near " + lastMarker + "</div>";
	console.log(msg);
	return [null, buffer.length, msg];
}
function parseAddition(buffer, index) {
	var results = "";
	var [xmlEnd, , next] = getNextXml(buffer, index); // find closing tag of addition
	if (xmlEnd.startsWith("<note")) {
		let result;
		results = buffer.slice(index, next - xmlEnd.length); // extract portion of add before note
		results = "<span class='sfm-add'>" + results + "</span>";
		[result, index] = parseFootnote(buffer, next); // absorbs all of footnote
		results += result;
		[xmlEnd, , next] = getNextXml(buffer, index);
	}
	if ("</char>" !== xmlEnd)
		console.log("Error " + xmlEnd + " mis-paired with style add at " + lastMarker);
	results +=
		"<span class='sfm-add'>" +
		buffer.slice(index, next - xmlEnd.length) +
		"</span>";
	return [results, next];
}
function getElement(buffer, index) {
	const start = buffer.indexOf("<", index);
	const end = buffer.indexOf(">", start);
	const result =
		start < 0 ? ["", buffer.length] : [buffer.slice(start, end + 1), end + 1];
	return result;
}
function getAttribute(element, attribute) {
	// assumes a quoted simple value
	var start = element.indexOf(attribute + '="');
	if (start < 0) return null;
	start += attribute.length + 2;
	const end = element.indexOf('"', start);
	return element.slice(start, end);
}

export default usxToHtml;
