import { useState } from "react";
//const Checkbox2 = () => {
const Checkbox2 = (props) => {
	//   const defaultChecked = checked ? checked : false;
	//   const [isChecked, setIsChecked] = useState(defaultChecked);
	const [initial, setInitial] = useState(props.initial);
	const [isChecked, setIsChecked] = useState(props.initial);

	if (props.initial !== initial) {
		// whenever parent changes, follow it
		setIsChecked(props.initial);
		setInitial(props.initial);
	}

	const handleCheck = () => {
		const newValue = !isChecked;
		setIsChecked(newValue);
		props.onCheck(props.id, newValue);
	};

	return (
		<div className="checkbox-wrapper">
			<input
				type="checkbox"
				checked={isChecked}
				onChange={handleCheck}
				// className={isChecked ? "checked" : ""}
				// {...props}
			/>
		</div>
	);
};
export default Checkbox2;
