import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import * as recent from "../utilities/recent";

const LastViewed = React.forwardRef((props, ref) => {
	let [hasRecent, setHasRecent] = useState(false);
	let items = useRef([]);
	const { t } = useTranslation();

	//console.log("LastViewed...");
	//console.log(items.current);
	let nextId = 1;
	function clickHandler(node) {
		//console.log(node);
		props.callback(node);
	}
	function getCollection(path) {
		//console.log("getCollection");
		//console.log(path);
		let parts = path.split("/");
		let result = parts[1];
		if (parts.length > 3) result += " / " + parts[2];
		return result;
	}
	function getDateTimeString(node) {
		let dt = new Date(node.dateTime * 1000);
		let str = "";
		str = dt.toLocaleString();
		str = str.replace(/\/[0-9]*, /, " ");
		str = str.replace(/:[0-9]* /, " ");
		return <>{str}</>;
		//let str = dt.toLocaleString('default', { month:'short',day:'numeric', });
	}

	useEffect(() => {
		//console.log("home effect");
		const fetchData = async () => {
			let temp = [];
			temp = await recent.getAll();
			//console.log(temp);
			//console.log("receive all ");
			items.current = temp;
			//console.log(items.current);
			setHasRecent(items.current && items.current[0]);
		};
		fetchData();
	}, []);

	try {
		return (
			<>
				{hasRecent && (
					<>
						<Typography
							variant="body1"
							component="div"
							sx={{ mt: 0, mb: 0.3 }}
						>
							{t("RecentlyViewed")}
						</Typography>
						<Grid
							container
							wrap="nowrap"
							spacing={1}
							sx={{ overflow: "auto" }}
						>
							{items.current.map((node, index) => (
								<Grid
									item
									key={nextId++}
									xs={12}
									sm={6}
									md={3}
									sx={{ minHeight: 110 }}
								>
									<Button
										sx={{
											minWidth: 150,
											minHeight: 75,
											textTransform: "none",
											boxShadow: 2,
											alignItems: "start"
										}}
										onClick={() => {
											clickHandler(items.current[index]);
										}}
									>
										<Stack>
											<Typography
												variant="caption"
												component="div"
												style={{ lineHeight: 1.3 }}
											>
												{getCollection(node.path)}
											</Typography>
											<Typography
												variant="subtitle1"
												component="div"
												style={{ lineHeight: 1.8 }}
											>
												{node.shortName +
													" " +
													(node.verse ||
														node.time ||
														"")}{" "}
											</Typography>
											<Typography
												variant="caption"
												component="div"
												style={{ lineHeight: 1.3 }}
											>
												<div>{getDateTimeString(node)}</div>
											</Typography>
										</Stack>
									</Button>
								</Grid>
							))}
						</Grid>
					</>
				)}
			</>
		);
	} catch (e) {
		return <p>Recent Items Error</p>;
	}
});

export default LastViewed;
