import React from "react";
import { HistoryProvider } from "./context/HistoryContext";
import App from "./App"; // imports withSplashScreen<App> to do some setup ahead of (optional) login screen

const OuterApp = () => {
	console.log("outerapp");

	return (
		<HistoryProvider>
			<App />
		</HistoryProvider>
	);
};

export default OuterApp;
