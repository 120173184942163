// wrapped crypto routines
//
//  initKey(password, time) =>  generate a key from password; auto destroy after time seconds
//                              (each call restarts the timer)
//  testKey()               =>  return true if there is a key, otherwise false
//  encrypt(buffer)         =>  encrypt a string buffer (to save it encrypted)
//  decrypt(buffer)         =>  return a string buffer

import { Uint8ToBase64, base64ToUint8 } from "./base64";
import * as wrap from "../utilities/wrap.js";

const salt_len = 16;
const iv_len = 16;
const authToken = "abc";

// outward facing routines
// setOptions returns true or false
async function setOptions(options, data) {
	// use these to configure encryption
	//console.log("setOptions "+options+" "+data);
	let s = window.crypto.subtle;
	let noSubtle = !s || !s.digest || !s.importKey || !s.deriveKey;
	if (noSubtle || !s.wrapKey || !s.unwrapKey) {
		console.error("no crypto subtle");
		return false;
	}
	let saltOp = localStorage.getItem("c");
	let mustUpdate = false;
	if (saltOp) {
		saltOp = base64ToUint8(saltOp);
	} else {
		// first pass, generate persistent random salt
		// with options in low 2 bits
		saltOp = window.crypto.getRandomValues(new Uint8Array(salt_len));
		mustUpdate = true;
	}
	let existing = saltOp[15] & 0x3;
	if (!mustUpdate) mustUpdate = (options & 0x3) !== existing;
	if (mustUpdate) {
		// re-write if new or changed
		let byte15 = (saltOp[15] & 0x7c) + (options & 0x3);
		saltOp[15] = saltOp[15] & 0x80 ? byte15 - 0x80 : byte15;
		localStorage.setItem("c", Uint8ToBase64(saltOp));
	}
	let st = getEtime();
	saltOp[15] = saltOp[15] & 0xfc; // remove lower 2 bits so that options can change over time
	return initOpaque(st, saltOp, data); // without needing to re-encrypt everything
}
function getEtime() {
	let st = window.document.expTime.startT;
	const ind = st.indexOf(".") + 1;
	// milliseconds always special value
	let ms = parseInt(st.substring(ind)) + 137;
	if (ms > 999) ms -= 1000;
	st = st.substring(3, ind) + ms; // shrink to 2 year date, replace milliseconds (obfuscation)
	return st;
}
async function confirm(sp) {
	// uses a stronger digest than auth
	console.log("safe.confirm...");
	const encoder = new TextEncoder();
	const h = localStorage.getItem("b"); // hash base64
	const et = getEtime(); // secret prefix
	let hash = await window.crypto.subtle.digest("SHA-256", encoder.encode(et + sp));
	const test = Uint8ToBase64(hash);
	// console.log(test);
	// console.log(h);
	// console.log(" good login? "+ (test === h));
	return test === h;
}

//internal routines
// Password-Based Key Derivation Function 2
async function PBKDF2(
	encoded_p,
	salt,
	iterations,
	length,
	hash,
	algorithm = "AES-CBC"
) {
	// console.log("enc p "+encoded_p);
	if (!window.crypto.subtle.importKey) console.log("no importKey!!!");
	// else console.log(window.crypto.subtle.importKey);
	const keyMaterial = await window.crypto.subtle.importKey(
		"raw",
		encoded_p,
		{ name: "PBKDF2" },
		false,
		["deriveKey"]
	);
	//console.log("keyMaterial "+keyMaterial);
	//console.log("salt "+salt);
	const key = await window.crypto.subtle.deriveKey(
		{
			name: "PBKDF2",
			salt: encoded_p,
			iterations,
			hash
		},
		keyMaterial,
		{ name: algorithm, length },
		true,
		["encrypt", "decrypt"]
	);
	return key;
}
async function digest(data) {
	const encoder = new TextEncoder();
	let encoded = encoder.encode(data);
	let hash = await window.crypto.subtle.digest("SHA-256", encoded);
	let base64 = Uint8ToBase64(hash);
	return base64;
}
async function auth(token) {
	let storage = window["localStorage"];
	const test1 = "?" + authToken;
	const test2 = storage.getItem("f");
	const test3 = await digest(token);
	// console.log(token);
	// console.log(test1 + "  " + test2 + "  " + test3);
	if (token === test1) {
		if (!test2) storage.setItem("f", test3);
		return true;
	}
	return test2 === test3;
}
async function initOpaque(et, salt, pw) {
	//secret password for encryption, salt, pw for periodic login
	// console.log("initOpaque "+et+" "+pw);
	const encoder = new TextEncoder();
	const encoded_et = encoder.encode(et); // encryption password
	let saltWrap = window.crypto.getRandomValues(new Uint8Array(salt_len));
	let hash = await window.crypto.subtle.digest("SHA-256", encoder.encode(et + pw));
	if (pw && pw.length > 0) {
		if (!auth(pw)) return false; // allow pw to authenticate for beta users
		localStorage.setItem("b", Uint8ToBase64(hash)); // hashed secret
	}
	let hash2 = await window.crypto.subtle.digest("SHA-256", encoded_et);
	let stringHash = Uint8ToBase64(hash2);
	window.document.expTime.et = stringHash; // hashed et for wrapping
	window.document.expTime.sa = saltWrap; // session salt for wrapping key
	const key = await PBKDF2(encoder.encode(stringHash), salt, 50000, 256, "SHA-256"); // generate encryption key
	// console.log(key);
	// console.log(await window.crypto.subtle.exportKey("raw",key));
	const wrapped = await wrap.wrap(hash2, key, saltWrap); // wrap (lightly encrypt) that key
	const encWrapped = Uint8ToBase64(wrapped);
	window.document.expTime.key = encWrapped;
	return true;
}

// const closeOpaque = () => {
// }

// take String (possibly containing >16 bit char codes) and return AES encrypted String
async function makeOpaque(buffer) {
	const tl = window.document.expTime;
	const et = base64ToUint8(tl.et);
	const s = tl.sa;
	const w = base64ToUint8(tl.key); // wrapped encryption key
	// console.log(w);
	const key = await wrap.unwrap(et.buffer, w.buffer, s);
	// console.log(key);
	const encoder = new TextEncoder();
	const encoded_text = encoder.encode(buffer); // convert to UTF-8 array of bytes
	const iv = window.crypto.getRandomValues(new Uint8Array(16));
	const encrypted = await window.crypto.subtle.encrypt(
		{ name: "AES-CBC", iv },
		key,
		encoded_text
	);
	// console.log("encrypted");
	// console.time("spread");
	var merged = [
		// merge 3 data items for storage
		// ...salt, // no need to save this salt, can be rebuilt
		...iv,
		...new Uint8Array(encrypted)
	];
	// console.timeEnd("spread");
	const result = Uint8ToBase64(merged);
	return result;
}
async function makeClear(buffer) {
	// console.log("makeClear");
	try {
		const tl = window.document.expTime;
		const et = base64ToUint8(tl.et);
		const s = tl.sa;
		const w = base64ToUint8(tl.key); // base64 to Uint8[] wrapped key
		// console.log(tl.key);
		// console.log(w);
		if (!(et && s && w)) {
			console.log("bad encryption data!");
			console.log(et);
			console.log(s);
			console.log(w);
			throw new Error("bad encryption data!");
		}
		const key = await wrap.unwrap(et.buffer, w.buffer, s);
		// console.log("unwrapped key..");
		// console.log(key);
		// console.log(await window.crypto.subtle.exportKey("raw",key));
		const decoder = new TextDecoder();
		const array = base64ToUint8(buffer); // recover binary data
		// console.log("array Uint8 length "+array.length);
		// const salt = array.slice(0, salt_len); // salt only needed if regenerating key
		const iv = array.slice(0, iv_len); // extract initialization vector (no salt)
		// console.log("iv array length "+iv.length);
		const encrypted = new Uint8Array(array.slice(iv_len));
		// console.log("encrypted length "+encrypted.length);
		// console.log("decrypting...");
		const decrypted = await window.crypto.subtle.decrypt(
			{ name: "AES-CBC", iv: iv },
			key,
			encrypted // array.slice(salt_len + iv_len)
		);
		const result = decoder.decode(decrypted); // byte stream back to multi-byte characters
		// console.log("returning "+result.length+" bytes");
		return result;
	} catch (e) {
		console.log(e);
		return "Error in makeClear";
	}
}
function muddy(t) {
	const encoder = new TextEncoder();
	let temp = encoder.encode(t);
	for (let i = 0; i < temp.length; i++) {
		temp[i] += (i & 3) + 1;
	}
	return Uint8ToBase64(temp);
}
function wash(t) {
	let temp = base64ToUint8(t);
	const decoder = new TextDecoder();
	for (let i = 0; i < temp.length; i++) {
		temp[i] -= (i & 3) + 1;
	}
	return decoder.decode(temp);
}

export {
	setOptions,
	confirm,
	digest,
	initOpaque,
	makeOpaque,
	makeClear,
	auth,
	muddy,
	wash
};
