import * as safe from "../utilities/safe.js";

// convenience wrappers for typical / simple get put transactions
// opens the database, initializes a transaction on the specified object store
// and returns that in an extended event via the onSuccess argument
function openTrOs(dbName, osName, transactionOption, onDbUpgrade, onSuccess, onError) {
	let dbOpen = indexedDB.open(dbName, 1);
	dbOpen.onupgradeneeded = (event) => {
		console.log("dbOpen onupgradeneeded for " + dbName);
		if (onDbUpgrade) {
			onDbUpgrade(event);
		} else {
			let db = event.target.result;
			["cache", "itemCache"].forEach((table) => {
				if (!db.objectStoreNames.contains(table)) {
					console.log("adding object store " + table + "...");
					const osRequest = db.createObjectStore(table);
					osRequest.onsuccess = (event) => {
						console.log("...object store " + table + " created");
					};
					osRequest.onerror = (event) => {
						console.error(
							"failed to create object store " +
								table +
								" in database " +
								dbName
						);
					};
				}
			});
		}
	};
	dbOpen.onsuccess = (event) => {
		//console.log(dbName + " open onsuccess");
		//console.log(event);
		let db = event.target.result;
		let tr;
		try {
			tr = db.transaction(osName, transactionOption);
		} catch (e) {
			console.log(e);
		}

		tr.onsuccess = (event) => {
			// console.log("tr.onsuccess...");
			//let tr = event.result;
			//console.log(tr);
		};
		tr.onerror = (event) => {
			console.error(dbName + " transaction error");
			if (onError) onError(event);
		};
		let os = tr.objectStore(osName);
		onSuccess(os);
	};
	dbOpen.onerror = (event) => {
		console.error(dbName + " error on open");
	};
	//console.log("TrOs... returning");
}

function getFromDB(table, name) {
	let p = new Promise(async function (resolve, reject) {
		if (!name || name.length < 1) resolve(null);
		let key = await safe.digest(name);
		// console.log("get, key " + key);
		try {
			let getItem = (os) => {
				// transaction to do when database is ready
				let countReq = os.count(key);
				countReq.onsuccess = (event) => {
					let result = event.target && event.target.result;
					if (result) {
						//only act if there is something to get
						let getReq = os.get(key);
						getReq.onsuccess = (event) => {
							result = getReq.result;
							// console.log(result);
							const makeDataClear = async (encrypted) => {
								// console.log("make clear");
								// console.log(encrypted);
								let result2 = await safe.makeClear(encrypted);
								// console.log(result2);
								resolve(result2);
							};
							makeDataClear(result); // allow db thread to finish early
						};
						getReq.onerror = (event) => {
							console.error("db error");
							reject({ message: "Error in db" });
						};
					} else {
						console.log(name + "  not found in " + table);
						resolve(null);
					}
				};
				countReq.onerror = (event) => {
					console.log("Error executing 'count(key)'");
					reject({ message: "Error in db" });
				};
			};
			openTrOs("hlm", table, "readonly", null, getItem, null);
		} catch (error) {
			console.error(error.message);
			console.error("while getting item from DB");
			reject({ message: "Error in db" });
		}
	});
	return p;
}

function putToDB(table, name, data) {
	// console.log("putToDB "+name);
	//console.log(data);
	let p = new Promise(async function (resolve, reject) {
		let key = await safe.digest(name);
		// console.log("put, key " + key);
		let blob = await safe.makeOpaque(data);
		try {
			const putItem = (os) => {
				// console.log(os);
				let req = os.put(blob, key);
				req.onsuccess = (event) => {
					// console.log("put OK");
					resolve(key);
				};
				req.onerror = (event) => {
					console.error("Error, failed to put " + name);
					reject({ message: "Error on put to DB " + name });
				};
			};
			openTrOs("hlm", table, "readwrite", null, putItem, null);
		} catch (error) {
			console.error(error.message);
			console.error("while getting bookkeeping item from DB");
			reject({ message: "Error on put to db" });
		}
	});
	return p;
}

function testInDB(table, name) {
	let promise = new Promise(async function (resolve, reject) {
		let key = await safe.digest(name);
		try {
			let getItem = (os) => {
				// transaction to do when database is ready
				let countReq = os.count(key);
				countReq.onsuccess = (event) => {
					let result = event.target && event.target.result;
					resolve(result > 0);
				};
				countReq.onerror = (event) => {
					console.log("Error executing 'count(key)'");
					reject({ message: "Error in db" });
				};
			};
			openTrOs("hlm", table, "readonly", null, getItem, null);
		} catch (error) {
			console.error(error.message);
			console.error("while testing for item in DB");
			reject({ message: "Error in db" });
		}
	});
	return promise;
}

function testEachInDB(table, names) {
	let promise = new Promise(async function (resolve, reject) {
		if (!names || names.length < 1) resolve([]);
		let hashes = await getKeys(table);
		//console.log(hashes);
		let lookup = new Map();
		for (let i = 0; i < hashes.length; i++) {
			lookup[hashes[i]] = true;
			//console.log(hashes[i]+" "+lookup[hashes[i]]);
		}
		//console.log(lookup);
		//console.log(names);
		let results = [];
		for (let i = 0; i < names.length; i++) {
			let hash = await safe.digest(names[i]);
			//console.log(names[i]+" "+hash+" "+lookup[hash]);
			results.push(lookup[hash] ? true : false);
		}
		resolve(results);
	});
	return promise;
}

function deleteFromDB(table, name) {
	// console.log("delete "+name+" from "+table);
	let promise = new Promise(async function (resolve, reject) {
		let key = await safe.digest(name);
		try {
			let getItem = (os) => {
				// transaction to do when database is ready
				let countReq = os.count(key);
				countReq.onsuccess = (event) => {
					let result = event.target && event.target.result;
					if (result > 0) {
						let delRequest = os.delete(key);
						delRequest.onsuccess = (event) => {
							resolve();
						};
						delRequest.onerror = (event) => {
							console.log("Error deleting " + name);
							reject({ message: "Error in db" });
						};
					} else {
						resolve();
					}
				};
				countReq.onerror = (event) => {
					console.log("Error executing 'count(key)'");
					reject({ message: "Error in db" });
				};
			};
			openTrOs("hlm", table, "readwrite", null, getItem, null);
		} catch (error) {
			console.error(error.message);
			console.error("while testing for item in DB");
			reject({ message: "Error in db" });
		}
	});
	return promise;
}
function clearDB(table) {
	let promise = new Promise(async function (resolve, reject) {
		try {
			const clearTable = (os) => {
				let req = os.clear();
				req.onsuccess = (event) => {
					resolve(0);
				};
				req.onerror = (event) => {
					console.error("Error, failed to clear " + table);
					reject({ message: "Error in db" });
				};
			};
			openTrOs("hlm", table, "readwrite", null, clearTable, null);
		} catch (error) {
			console.error(error.message);
			console.log("Error, exception trying to clear table " + table);
			reject({ message: "Error in db" });
		}
	});
	return promise;
}
function getKeys(table) {
	let promise = new Promise(async function (resolve, reject) {
		let dbOpen = indexedDB.open("hlm");
		dbOpen.onsuccess = (event) => {
			let db = event.target.result;
			let os;
			try {
				os = db.transaction(table).objectStore(table);
			} catch (e) {
				resolve([]);
				return;
			}
			if (os) {
				const keysTr = os.getAllKeys();
				keysTr.onsuccess = (event) => {
					const keys = event.target.result;
					resolve(keys);
				};
			} else {
				resolve([]); // no table, so no keys
			}
		};
		dbOpen.onerror = (event) => {
			console.error("getKeys error on db open");
			console.log(event);
			reject({ message: "Error in db" });
		};
	});
	return promise;
}

export {
	openTrOs,
	getFromDB,
	putToDB,
	testInDB,
	testEachInDB,
	deleteFromDB,
	clearDB,
	getKeys
};
