import axios from "axios";
import * as database from "./database.js";

// initializeServices returns a promise
function initializeServices() {
	return new Promise(async function (resolve, reject) {
		if (!window.netServices || typeof window.netServices !== "object") {
			let axiosPromise = axios.get("netServices.json");
			let dbPromise = database.getFromDB("cache", "NetServices");
			Promise.all([axiosPromise, dbPromise])
				.then(([webResponse, dbData]) => {
					console.log(webResponse);
					let webData = webResponse?.data;
					if (
						webResponse &&
						webResponse.status !== 222 &&
						webResponse.status < 400 &&
						webData &&
						webData.default
					) {
						window.netServices = webData;
						database.putToDB(
							"cache",
							"NetServices",
							JSON.stringify(webData)
						);
						resolve(true);
					} else if (dbData) {
						window.netServices = JSON.parse(dbData);
						resolve(true);
					} else {
						window.netServices = null;
						reject({ message: "failed to initialize net services" });
					}
				})
				.catch((error) => {
					reject({ message: "Error: no net services" });
				});
		} else {
			resolve(true);
		}
	});
}

function getItem(file, service) {
	// try encrypted cache, then web
	// console.log("getItem " + file + " " + service);
	return new Promise(function (resolve, reject) {
		initializeServices() // start fetch of services if not yet done
			.then(() => {
				if (!file || file.length < 3)
					reject({ message: "Error: invalid filename in catalog" });
				database
					.getFromDB("itemCache", file)
					.then((result) => {
						if (result && result.length && result.length > 0) {
							resolve(result);
						} else {
							// console.log(" file not found in cache");
							_fetchFromWeb(file, service)
								.then((result) => {
									resolve(result);
								})
								.catch(() => {
									reject({ message: "Error getting the file" });
								});
						}
					})
					.catch(() => {});
			})
			.catch(() => {
				reject({ message: "Error initializing file services" });
			});
	});
}

async function fetchFromWeb(file, service) {
	// console.log("fetch from web " + file);
	// console.log("service " + service);
	return new Promise(function (resolve, reject) {
		initializeServices()
			.then(() => {
				resolve(_fetchFromWeb(file, service));
			})
			.catch((error) => {
				reject(error);
			});
	});
}

function _fetchFromWeb(file, service) {
	// console.log(file + " " + service);
	return new Promise(function (resolve, reject) {
		// console.log(file + " " + service);
		let s = service || "default";
		let prefix = window.netServices[s];
		if (file.startsWith("http")) prefix = "";
		else if (!prefix.endsWith("/") && !file.startsWith("/")) prefix += "/";
		let url = prefix + file;
		let mode = url.startsWith(window.origin) ? "no-cors" : "cors";
		let streaming = file.endsWith("mp3"); // add more later
		let isJson = file.endsWith(".json");
		// let special = file.endsWith("sfm") || file.endsWith("SFM");
		let axiosPromise;
		let headers;
		let options;
		if (streaming) {
			headers = { accept: "audio/mpeg", range: "bytes=0-" };
			options = { headers: headers, mode: mode, responseType: "blob" };
		} else {
			if (isJson) headers = { contentType: "application/json", charset: "UTF-8" };
			else headers = { contentType: "text/plain", charset: "UTF-8" };
			options = { headers: headers, mode: mode };
		}

		try {
			axios
				.get(url, options)
				.then((response) => {
					// console.log("axios response");
					// console.log(response);
					if (response.status === 222) {
						// TODO: change to an error code? 222 won't show as error in network panel (good?)
						console.log("status 222");
						reject({ status: 222, message: response.data }); // HeartLMD is offline
					} else {
						resolve(response.data);
					}
				})
				.catch((error) => {
					console.log("axios catch");
					console.log(error);
					reject({
						status: error?.response?.status,
						message: "Error retrieving data"
					});
				});
		} catch (e) {
			console.log("Exception in io");
			console.log(e);
			reject({ message: "Error, exception in in" });
		}
	});
}

export { initializeServices, getItem, fetchFromWeb };
