import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import CatalogList from "./CatalogList.js";
import { useTranslation } from "react-i18next";
import * as bookkeeping from "../utilities/bookkeeping.js";
import * as database from "../utilities/database.js";
<link rel="manifest" href="/manifest.json"></link>;

const Local = React.forwardRef((props, ref) => {
	const { selected, handleBack, offline, ...others } = props;
	const [ready, setReady] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [catalog, setCatalog] = useState(JSON.parse("{}"));

	console.log("Local ready " + ready);
	const { t } = useTranslation();
	const errMsgNFD = t("NoFilesDownloaded");

	let actionIcons = [];
	actionIcons[0] = [
		// offline
		<DeleteOutlinedIcon />,
		<SyncIcon />
	];
	actionIcons[1] = [
		// online
		<DeleteOutlinedIcon />,
		<SyncIcon />
	];

	function handleSelected(node) {
		selected(node);
	}

	function handleDelete(nodes) {
		// console.log("handleDelete in Local "+nodes.length);
		let p0 = new Promise(async function (resolve, reject) {
			// update memory copy of catalog
			let newLocal = bookkeeping.deleteFromCatalog(catalog, nodes);
			if (newLocal) {
				setCatalog(newLocal);
				await bookkeeping.putLocal(newLocal);
				resolve();
			} else {
				//TODO: report problem somewhere: Failed to delete node(s)
				resolve(); // don't want to propagate to CatalogList
			}
		});
		let p1 = new Promise(async function (resolve, reject) {
			let files = nodes.map((n) => n.file);
			for (let i = 0; i < files.length; i++)
				await database.deleteFromDB("itemCache", files[i]);
			resolve();
		});
		return [p0, p1];
	}

	function getChildrenStatus(parent) {
		console.log("getChildrenStatus " + parent.name);
		let status = bookkeeping.testChildrenInCatalog(catalog, parent);
		// console.log(status);
		status = status.map((value) => value - 1); // 0 = present, -1 = missing
		// console.log(status);
		let contents = parent.contents;
		for (let i = 0; i < contents.length; i++) {
			let node = contents[i];
			if (node.contents && contents.length === 0) status[i] = -1;
			// if (node.contents) console.log(node.name+" "+node.contents.length+" "+status[i]);
		}
		return status;
	}

	useEffect(() => {
		const fetchCatalog = async () => {
			let local = await bookkeeping.getLocal();
			setCatalog(local);
			if (local.contents && local.contents.length > 0) setReady(true);
			else setEmpty(true);
		};
		fetchCatalog();
	}, []);

	// console.log("refreshing Local");
	return (
		<Box {...others} ref={ref} sx={{ pt: 9 }}>
			{ready && (
				<CatalogList
					catalog={catalog}
					getStatus={getChildrenStatus}
					onSelect={handleSelected}
					offline={offline}
					onAction={handleDelete}
					actionIcons={actionIcons}
					handleBack={handleBack}
				/>
			)}
			{empty && <Box>{errMsgNFD}</Box>}
		</Box>
	);
});

export default Local;
