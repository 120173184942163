import React, { useRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import * as recent from "../utilities/recent.js";
import * as database from "../utilities/database.js";
import * as io from "../utilities/io.js";
<link rel="manifest" href="/manifest.json"></link>;

const TextViewer = React.forwardRef((props, ref) => {
	const [data, setData] = useState("<span class='loader'></span>");
	let nodeRef = useRef(JSON.parse(JSON.stringify(props.node))); // get a copy of the node
	console.log("TextViewer");
	// console.log(nodeRef.current);

	const txtToHtml = (data) => {
		let result = data.replaceAll("\n", "</p>\n<p style='padding-top:4px;'>");
		const first = result.indexOf("</p>");
		result =
			"<h4>" + data.substring(0, first) + "</h4>" + result.substring(first + 4);
		return result;
	};

	useEffect(() => {
		// runs after render to start fetching data
		try {
			// get referenced data, either from IndexedDB or from web (or web cache)
			let file = nodeRef.current.file;
			let fetch = io.getItem(file);
			fetch
				.then((result) => {
					if (!result.startsWith("<h4")) {
						// if not cached version
						if (result.startsWith("offline")) {
							// do nothing for now, just display the message from service worker
						} else if (
							nodeRef.current.mediaType &&
							nodeRef.current.mediaType === "txt"
						) {
							result = txtToHtml(result);
						} else {
							//result = usxToHtml(result);
						}
						// start background task to cache the converted file,
						// returns a promise quickly
						database.putToDB("itemCache", nodeRef.current.file, result);
					}
					setData(result); // triggers a re-render with new data
					recent.update(nodeRef.current); // initial history update even though not rendered
				})
				.catch((error) => {
					console.log(
						"caught error in SfmViewer, status " +
							error.status +
							" " +
							error.message
					);
					console.log(error);
					setData(
						"<p style='margin:18px; color:DarkRed'>" + error.message + "</p"
					);
				});
		} catch (error) {
			console.error(error.message);
			setData("<p style='margin:18px; color:DarkRed'>" + error.message + "</p");
		}
	}, [props.node]);

	useEffect(() => {
		var timer = null;
		const handleScroll = (event) => {
			if (!timer) timer = setTimeout(processScroll, 250); // at most 4x per second
		};
		const processScroll = () => {
			timer = null;
			// console.log(window.scrollY);
			// TODO: update history if scroll increases by 1/2 window
		};
		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, []);

	return (
		<div {...props} ref={ref}>
			<Box sx={{ width: "100%", py: 8, px: 2, overflow: "hidden" }}>
				{" "}
				<div id="sfm-contents" dangerouslySetInnerHTML={{ __html: data }} />
			</Box>
		</div>
	);
});

export default React.memo(TextViewer);
