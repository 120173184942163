import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function DownloadLaterIcon() {
	return (
		<SvgIcon>
			{/* credit: icon from https://iconduck.com/icons/62021/download-later */}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 22 22"
				strokeWidth={0.75}
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="m11 3c-4.4319982 0-8 3.568-8 8 0 4.092537 3.0443284 7.441581 7 
					7.931641v-1.009766c-3.3993571-.483816-6-3.384659-6-6.921875 
					0-.242375.0113433-.480906.0351562-.716797.357195-3.5383664 
					3.32922-6.283203 6.9648438-6.283203 3.877999 0 7 3.122 7 7 0 
					.340784-.031513.672498-.078125 1h1.009766c.040649-.32811.068359-.660537.068359-1 
					0-4.432-3.568002-8-8-8zm-1 2v7h6v-1h-5v-6zm6 
					8v4.292969l-2.292969-2.292969-.707031.707031 3.292969 
					3.292969.207031.207031.207031-.207031 3.292969-3.292969-.707031-.707031-2.292969 
					2.292969v-4.292969z"
				/>
			</svg>
		</SvgIcon>
	);
}
export default DownloadLaterIcon;
