// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export function register(config) {
	console.log("register service worker... " + process.env.NODE_ENV);
	if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
		console.log("publicUrl " + publicUrl);
		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			console.log(
				"PUBLIC_URL is NOT same origin as app origin, will NOT register"
			);
			return;
		}

		window.addEventListener("load", () => {
			console.log("swRegistration: window load event...");
			const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
			const isLocalhost = Boolean(
				window.location.hostname === "localhost" ||
					// [::1] is the IPv6 localhost address.
					window.location.hostname === "[::1]" ||
					// 127.0.0.0/8 are considered localhost for IPv4.
					window.location.hostname.match(
						/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
					)
			);
			if (isLocalhost) {
				console.log("sw: is local host");
				// This is running on localhost. Let's check if a service worker still exists or not,
				// and register as appropriate
				checkValidServiceWorker(swUrl, config);

				// Add some additional logging to localhost, pointing developers to the
				// service worker/PWA documentation.
				navigator.serviceWorker.ready.then(() => {
					console.log(
						"This web app is being served cache-first by a service " +
							"worker. To learn more, visit https://cra.link/PWA"
					);
				});
			} else {
				// Is not localhost. Just register service worker
				registerValidSW(swUrl, config);
			}
		});
	}
}

function registerValidSW(swUrl, config) {
	console.log("registerValidSW " + swUrl);
	navigator.serviceWorker
		.register(swUrl)
		.then((registration) => {
			console.log("registration complete");
			console.log(registration);
			// registration.onupdatefound = (event) => {
			// 	console.log("onupdatefound, event:");
			// 	console.log(event);
			// 	if (registration.waiting) {
			// 		console.log("a new sw is waiting for old one to leave...?");
			// 	}
			// 	const installingWorker = registration.installing;
			// 	if (installingWorker == null) {
			// 		return;
			// 	}
			// 	console.log("onupdatefound, state is installing... ");
			// 	installingWorker.onstatechange = () => {
			// 		console.log("installingWorker state now " + installingWorker.state);
			// 		if (installingWorker.state === "installed") {
			// 			if (navigator.serviceWorker.controller) {
			// 				// === .active, i.e. there is already an active service worker
			// 				// At this point, the updated pre-cached content has been fetched,
			// 				// but the previous service worker will still serve the older
			// 				// content until all client tabs are closed.
			// 				console.log(
			// 					"New content is available and will be used when all " +
			// 						"tabs for this page are closed. See https://cra.link/PWA."
			// 				);
			// 				// Execute callback
			// 				if (config && config.onUpdate) {
			// 					config.onUpdate(registration);
			// 				}
			// 			} else {
			// 				// At this point, everything has been pre-cached.
			// 				// It's the perfect time to display a
			// 				// "Content is cached for offline use." message.
			// 				console.log("Content is cached for offline use.");
			// 				// Execute callback
			// 				if (config && config.onSuccess) {
			// 					console.log("onSuccess " + config.onSuccess);
			// 					config.onSuccess(registration);
			// 				}
			// 			}
			// 		}
			// 	};
			// };
			console.log("registerValidSW done");
		})
		.catch((error) => {
			console.error("Error during service worker registration:", error);
		});
}

function checkValidServiceWorker(swUrl, config) {
	// Check if the service worker can be found.
	// https://medium.com/dev-channel/two-http-headers-related-to-service-workers-you-never-may-have-heard-of-c8862f76cc60
	fetch(swUrl, {
		headers: { "Service-Worker": "script" }
	})
		.then((response) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			console.log("fetch sw response:");
			console.log(response);
			const contentType = response.headers.get("content-type");
			if (
				response.status === 404 ||
				(response.status !== 222 &&
					contentType != null &&
					contentType.indexOf("javascript") === -1)
			) {
				// No service worker found. Probably a different app. Reload the page.
				console.log(
					"no valid service worker returned, reloading in 15 seconds..."
				);
				console.log(response.status);
				console.log(contentType);
				// navigator.serviceWorker.ready.then((registration) => {
				// 	registration.unregister().then(() => {
				// 		setTimeout(() => {
				// 			window.location.reload();
				// 		}, 400);
				// 	});
				// });
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config);
			}
		})
		.catch(() => {
			console.log(
				"No internet connection found. App is running in offline mode."
			);
		});
}

export function unregister() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.ready
			.then((registration) => {
				registration.unregister();
			})
			.catch((error) => {
				console.error(error.message);
			});
	}
}
