import * as database from "../utilities/database.js";
// import * as safe from '../utilities/safe';

async function benchmark(keys) {
	// let local = window["localStorage"];
	// local.setItem("counter", 42);
	// let hashed = [{}];
	// let times = [];
	// times.push(Date.now());
	// // time hashing all keys
	// for (let key in keys) {
	//     hashed.push({"k":key,"h":safe.digest(key)});
	// }
	// times.push(Date.now());
	// // time to encrypt and store the tracking array
	// let data = JSON.stringify(hashed);
	// let encrypted = await safe.makeOpaque(data);
	// let storage = window["localStorage"];
	// storage.setItem("benchmark", encrypted);
	// times.push(Date.now());
	// // time getting all keys from database
	// await database.getKeys("itemCache");
	// times.push(Date.now());
	// // time to fetch and decrypt tracking array[cached]
	// let storage2 = window["localStorage"];
	// let encrypted2 = storage2.getItem("benchmark");
	// let data2 = await safe.makeClear(encrypted2);
	// let hashed2 = JSON.parse(data2);
	// times.push(Date.now());
	// // time to hash key and test if in db
	// for (let key in keys) {
	//     await database.testInDB("itemCache",key)
	// }
	// times.push(Date.now());
	// // time to test number of cached items unchanged
	// let storage3 = window["localStorage"];
	// let myCount = storage3.getItem("counter");
	// let reality = await database.getCount("itemCache");
	// times.push(Date.now());
	// // time to test array of names in DB
	// let results = await database.testEachInDB("itemCache",keys);
	// times.push(Date.now());
	// let elapsed = [];
	// for (let i=1; i<times.length; i++) {
	//     elapsed[i] = (times[i] - times[i-1]);
	// }
	// console.log("benchmarking "+keys.length+" items:");
	// console.log(elapsed[1]+" hash keys");
	// console.log(elapsed[2]+" array to localStorage");
	// console.log(elapsed[3]+" get all keys");
	// console.log(elapsed[4]+" array from localStorage");
	// console.log(elapsed[5]+" test each key in database individually");
	// console.log(elapsed[6]+" test of counters match: getCount vs fetch from localStorage");
	// console.log(elapsed[7]+" test array of keys in DB using getAllKeys");
	// console.log((elapsed.length-1)+" tests done");
	// console.log(results);
}
async function getLocal() {
	let str = await database.getFromDB("cache", "LocalCatalog");
	let localCatalog =
		str === null
			? { name: "LocalCatalog", path: "", contents: [] }
			: JSON.parse(str);
	return localCatalog;
}
async function putLocal(local) {
	let blob = JSON.stringify(local);
	return database.putToDB("cache", "LocalCatalog", blob);
}
async function addToLocal(catalog, items) {
	if (!items || !items.length) return null;
	let localCatalog = await getLocal(); // json structure
	let remaining = items;
	while (remaining.length > 0) {
		let node = catalog; // top of structure
		let local = localCatalog; // top of structure
		let parent = "";
		let path = remaining[0].path;
		const first = path.indexOf("/");
		if (first < 0) console.log("short path? " + path);
		let parts = first >= 0 ? path.split("/") : []; // array of names leading to this node, first is empty
		for (let i = 1; i < parts.length; i++) {
			let nodeName = parts[i];
			node = getNamedChild(node, nodeName); // node in catalog
			if (!node) return "Error: " + nodeName + " not found in source catalog";
			let child = getNamedChild(local, nodeName); // in local
			if (!child) {
				// insert into local if not already there
				const { contents, ...rest } = node; // trim all children in cloned 'rest'
				if (node.contents) rest.contents = []; // if master has children, add empty [] to rest
				child = rest;
				insertNodeById(local, child); // keep items sorted independent of order added
			}
			parent = local; // update references in local catalog
			local = child;
		}
		let other = []; // collect non-siblings here
		let parentId = parent.id; // last parent into which items[0] was added
		for (let i = 1; i < remaining.length; i++) {
			// process any remaining siblings without walking tree again
			let item = remaining[i];
			if (item.parent.id === parentId) {
				let child = getNamedChild(parent, item.name); // or make insertNodeById skip if equal
				if (!child) insertNodeById(parent, remaining[i]);
			} else {
				other.push(item); // collect non-siblings
			}
		}
		remaining = other; // iterate until remaining is empty
	}
	// console.log(localCatalog);
	await putLocal(localCatalog);
	return localCatalog;
}

function deleteFromCatalog(localCatalog, items) {
	// caller provides catalog, no need for async
	// console.log("deleting "+items.length);
	if (!items || !items[0] || !items[0].path) return null;
	let local = localCatalog; // top of structure
	let ancestors = [];
	let parent = items[0].parent; // all items must have same parent
	let path = parent.path;
	let parts = path.split("/"); // array of names leading to the parent, first is empty
	for (let i = 1; i < parts.length; i++) {
		// find the parent in the catalog
		let nodeName = parts[i];
		ancestors.push(local);
		local = getNamedChild(local, nodeName);
		if (!local) return null;
	}
	if (local.name !== parent.name) return null;
	let contents = local.contents;
	//console.log(contents);
	for (let i = 0; i < items.length; i++) {
		// process remaining siblings without walking tree again
		let name = items[i].name;
		for (let j in contents) {
			if (contents[j].name === name) {
				// console.log("found "+name+" at "+j);
				contents.splice(j, 1);
				//console.log(" length now "+contents.length);
				break;
			}
		}
	}
	if (contents.length > 0) {
		local.contents = contents; // replace the array
	} else {
		let deadBranch = local.name;
		while (ancestors.length > 0) {
			let ancestor = ancestors.pop();
			contents = ancestor.contents;
			for (let j in contents) {
				if (contents[j].name === deadBranch) {
					contents.splice(j, 1);
					break;
				}
			}
			if (contents.length > 0) break;
			deadBranch = ancestor.name;
		}
	}
	//console.log("ending with "+contents.length);
	return localCatalog;
}
function testChildrenInCatalog(localCatalog, parent) {
	// console.log("tCInC #children "+parent.contents.length);
	let results = [];
	let items = parent.contents;
	if (!items || !items[0] || !items[0].path) {
		console.log("bad item[0]? ");
		return results; // nothing to test, return []
	}
	let local = localCatalog; // top of structure
	let previous = local;
	let path = items[0].path;
	let parts = path.split("/"); // array of names leading to this node, first is empty
	for (let i = 1; i < parts.length; i++) {
		let nodeName = parts[i];
		// console.log("find "+nodeName+" in...");
		// console.log(previous);
		previous = local;
		local = getNamedChild(previous, nodeName);
		if (local === null) break;
		//console.log("found "+nodeName+" "+local.name);
	}
	//console.log("tCinC previous "+previous.name+" parent "+parent.name);
	results[0] = local ? 1 : 0;
	let foundParent = previous.name === parent.name;
	for (let i = 1; i < items.length; i++) {
		// process remaining siblings without walking tree again
		if (foundParent) {
			let item = items[i];
			let child = getNamedChild(previous, item.name); // or make insertNodeById skip if equal
			results[i] = child ? 1 : 0;
		} else {
			results[i] = 0;
		}
	}
	return results;
}
function getNamedChild(parent, name) {
	//console.log(" get "+name);
	//console.log(parent.contents);
	if (!parent || !parent.contents) return null;
	for (let i in parent.contents) {
		let child = parent.contents[i];
		if (child.name === name) return child;
	}
	return null;
}
function insertNodeById(parent, node) {
	if (!parent.contents) {
		parent.contents = [node];
		return;
	}
	let id = node.id;
	let array = parent.contents;
	let slot = 0;
	while (slot < array.length) {
		let child = array[slot];
		if (child.id > id) break; // insert at slot
		slot++;
	}
	if (slot >= array.length) {
		array.push(node);
	} else {
		array.splice(slot, 0, node);
	}
}
function handleMatomoPageview(customURL, documentTitle) {
	//web analytics
	var _paq = (window._paq = window._paq || []);

	_paq.push(["setCustomUrl", customURL]);
	_paq.push(["setDocumentTitle", documentTitle]);
	_paq.push(["trackPageView"]);
}
function handleMatomoCustomEvent(eventCategory, eventAction, eventName, value) {
	//web analytics
	value = value || "";

	var _paq = (window._paq = window._paq || []);

	_paq.push(["trackEvent", eventCategory, eventAction, eventName, value]);
}
export {
	getLocal,
	addToLocal,
	testChildrenInCatalog,
	deleteFromCatalog,
	putLocal,
	benchmark,
	handleMatomoPageview,
	handleMatomoCustomEvent
};
