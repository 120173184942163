//TODO: refactor into utilities.js
function dateToString(date) {
	const parts = [
		"0" + (date.getMonth() + 1),
		"0" + date.getDate(),
		"0" + date.getHours(),
		"0" + date.getMinutes(),
		"0" + date.getSeconds()
	].map((part) => part.slice(-2));
	const ms = "00" + date.getMilliseconds();
	const dateString =
		date.getFullYear() +
		"-" +
		parts.slice(0, 2).join("-") +
		" " +
		parts.slice(2).join(":") +
		"." +
		ms.slice(-3);
	return dateString;
}
export { dateToString };
