import React from "react";
import Box from "@mui/material/Box";
import SfmViewer from "./SfmViewer.js";
import AudioPresenter from "./audiopresenter/AudioPresenter.js";
import TextViewer from "./TextViewer.js";
import { useHistory } from "../context/HistoryContext.js";

const ItemPresenter = React.forwardRef((props, ref) => {
	const history = useHistory();

	console.log("ItemPresenter... ");

	let node = history.state().n; // handle navigation back to here
	if (!node) {
		node = JSON.parse(JSON.stringify(props.node)); // get a copy of the node
		history.replaceState({ ...history.state(), n: node }); // save for later
	}

	const mediaType = node ? node.mediaType : null; // route on mediaType

	let content;
	if (mediaType) {
		if (mediaType === "sfm" || mediaType === "usx")
			content = <SfmViewer node={node} />;
		else if (mediaType === "mp3" || mediaType === "wma")
			content = <AudioPresenter node={node} />;
		else if (mediaType === "txt") content = <TextViewer node={node} />;
		else content = <Box sx={{ mt: 10 }}>Unsupported Media Type {mediaType}</Box>;
	} else content = <Box sx={{ mt: 10 }}>Missing Media Information </Box>;

	return (
		<div {...props} ref={ref}>
			{content}
		</div>
	);
});

export default ItemPresenter;
