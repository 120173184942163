import React, { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import * as recent from '../../utilities/recent.js';
import * as io from '../../utilities/io.js';
import * as database from '../../utilities/database.js';
import AudioDisplayTrack from './AudioDisplayTrack.js';
import Controls from './Controls.js';
import ProgressBar from './ProgressBar.js';
import '../../styles/index.css';
import '../../styles/customize-progress-bar.css'


const AudioPresenter = React.forwardRef((props, ref) => {
    /* TODO:
      - Improve phone UI
            maybe: https://freefrontend.com/css-music-players/
                curr fav but uses Sassy CSS (SCSS): Neumorphism (Soft UI) Music Player 
                        https://www.vanessalidesign.com/neumorphic-music-player
      - Improve home page history ref
      - Implement fwd/back (if more nodes in curr hierarchy)  
    */

    // app 
    let nodeRef = useRef(JSON.parse(JSON.stringify(props.node)));
    const [data, setData] = useState("<span class='loader'></span>");

    // audio specific
    const [currentTrack, setCurrentTrack] = useState(nodeRef.current);
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef();
    const progressBarRef = useRef();


    useEffect(() => { // runs after render to start fetching data
        try { // get referenced data, either from IndexedDB or from web (or web cache)
            let file = nodeRef.current.file;
            let fetch = io.getItem(file,nodeRef.current.service);
            fetch.then((result) => {
                // TODO: result is a streamReader and might or might not have
                // all of the bytes.  Each (async) result.read will retrieve one
                // byte.  
                // Option 1: loop reading to get all the chunks (delays start)
                // Option 2: Tee the result (2 streamReaders sharing one source)
                // and on one branch await result.read() (one chunk) looping until
                // done, and then putToDB the concatenated result
                // the other branch can be immediately give to the audio element
                // but it might be partial.
                // Option 3: use MediaSource to stream the chunks while also building the 
                // data to put into the database
                // https://stackoverflow.com/questions/70002015/streaming-into-audio-element
                // Option 4: create a custom ReadableStream which can be used by <audio>
                // while it carries on reading from the response's readable stream
                // https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
                // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
                
                // database.putToDB("itemCache", nodeRef.current.file, result);
                let href;
                try {
                    href = URL.createObjectURL(result);
                } catch (e) {console.log(e)}
                if (href) setData(href); // triggers a re-render with new data
                let audio = document.getElementById('hlmdAudio'); // tagged #:# (2 numbers)
                audio.src = href;
                audio.load();

                //recent.update(nodeRef.current);
            });
        } catch (error) {
            console.error(error.message);
            setData("<p style='margin:18px; color:DarkRed'>" + error.message + "</p>");
        }
        if (!nodeRef.current) { // bookkeeping 
            // recent.update(nodeRef.current); // initial history update even though 
        }
    }, [props.node]);


    // props contains a node (JSON item)
    // data is span or mp3 binary after fetch
    // <audio id='hlmdAudio' src={"https://pub-ace30ec5614d46e2a994dc424eccdbc4.r2.dev/MondayMedley.mp3"} />


    return (
        <div {...props} ref={ref}>
            <Box sx={{ py: 4 }}> {/* smaller padding since floating div above consumes space */}
                <br></br>

                {/* <div className='audio-player'>
                    <div className='inner'>
                        <AudioDisplayTrack {...{ currentTrack, audioRef, setDuration, progressBarRef }} />
                        <Controls {...{ audioRef, progressBarRef, duration, setTimeProgress }} />
                        <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
                    </div>
                </div> */}
            </Box>

            <Box sx={{ py: 4 }}> {/* smaller padding since floating div above consumes space */}
                <audio id='hlmdAudio' controls />
            </Box>

        </div>
    );
})

export default React.memo(AudioPresenter);